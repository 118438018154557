.checkout {
  .checkout-progress {
    li {
      float: left;
      margin: 24px 24px 0;
      list-style-type: decimal;
      width: auto;
    }
  }
  &.review {
    .checkout-progress {
      .checkout-progress__review {
        font-weight: bold;
      }
    }
  }
  &.billing {
    .checkout-progress {
      .checkout-progress__billing {
        font-weight: bold;
      }
    }
  }
  &.shipping {
    .checkout-progress {
      .checkout-progress__shipping {
        font-weight: bold;
      }
    }
  }
  &.confirm {
    .checkout-progress {
      .checkout-progress__confirm {
        font-weight: bold;
      }
    }
  }
}

.pg-checkout {
  @include breakpoint($small-down) {
    .site-footer {
      padding-top: 0px !important;
      .site-footer__wrap {
        padding-bottom: 0px;
        .block-nodeblock {
          &:first-of-type {
            background-color: $color-gray-background;
            color: $color-black;
          }
        }
      }
    }
  }
}
