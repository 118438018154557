.sign-in-page {
  .return-user,
  .new-account {
    input {
      width: 100%;
      margin-top: 10px;
      &[type='button'] {
        border-radius: 0;
      }
    }
  }
}

.address-form__fieldset {
  .default_ship_container {
    span {
      &.label-content {
        display: inline !important;
      }
    }
  }
}

.site-utils__dropdown--account {
  .sign-in {
    form {
      input {
        width: 100%;
      }
    }
    .registration__show-pass {
      margin: 5px 0 10px 0;
    }
  }
}
