.profile-page {
  .h_sms_promotions {
    text-indent: 20px;
  }
  .mobile_email_signup,
  .sms_promo_container {
    margin-left: 10px;
    padding: 10px;
  }
  .profile-info__fieldset {
    .title_container {
      width: 100%;
    }
  }
}

.account-page {
  .account-page__content {
    .section-head,
    .section-header {
      @include h13;
      &::before {
        top: unset;
        bottom: 0;
      }
      &__header {
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
    }
    .account-profile,
    .account-orders,
    .account-payment,
    .account-address {
      .section-head,
      .section-header {
        @include breakpoint($medium-up) {
          width: 100%;
          padding: 0;
        }
      }
      .section-content {
        @include breakpoint($medium-only) {
          word-break: break-word;
        }
      }
    }
  }
}
