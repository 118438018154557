$disabled-color: #cccccc;
$disabled-cursor: text;

.checkout {
  .links-panel {
    background: $color-gray-background;
    padding: 16px 20px;
    li {
      display: block;
      border-width: 0;
    }
  }
  @include breakpoint($small-down) {
    .order-summary-panel__title,
    .links-panel__title {
      font-size: 18px !important;
      padding-right: 45px !important;
      .expando-block__icon {
        i {
          float: none;
          position: absolute;
          right: 20px;
          top: 15px;
        }
      }
    }
  }
}

#one-offer-only {
  .continue-shopping {
    border-width: 0px;
  }
}

.checkout .checkout__content {
  #viewcart-panel {
    @include viewcart-panel;
  }
  #bottom-viewcart-buttons {
    @include viewcart-panel;
  }
  .checkout-buttons-container input.disabled {
    @include disable-button();
  }
  .viewcart-panel__title {
    display: block;
  }
}

.margin_top_bottom {
  margin: 0.8em 0;
}

.below-columns {
  .recommended-products-panel {
    .recommended-item {
      form input {
        word-wrap: break-word;
        white-space: pre-wrap;
        height: auto !important;
        line-height: 20px;
        background-color: #000;
      }
    }
  }
}

.viewcart-header {
  .viewcart-panel__title {
    .js-display_cart_item_count {
      text-indent: 0;
    }
  }
}

.checkout-co-enabled {
  #samples-panel {
    &.cart-samples-panel {
      li {
        &.product {
          min-height: 270px;
        }
      }
    }
  }
}

#viewcart {
  #top {
    .site-header {
      height: auto;
    }
  }
  .wp_message {
    color: $color-green;
    @include breakpoint($small-down) {
      padding: 0 25px 10px;
    }
  }
}

.checkout-co-enabled {
  .left {
    #viewcart-panel {
      .viewcart-panel__content {
        .cart-items {
          .cart-item__total {
            display: block;
            line-height: unset;
            margin: unset;
          }
          .cart-item__desc-info {
            display: flex;
            flex-direction: column;
          }
          .cart-item__price--mobile-only {
            width: 100%;
            margin-#{$ldirection}: 0;
            display: flex !important;
            flex-direction: row;
            visibility: visible;

            .price-label {
              display: flex;
              flex-direction: row;
            }
            .discount-old-price {
              text-decoration: line-through;
            }
            .discount-price-off {
              line-height: unset;
            }
            .total {
              padding-top: 0;
              flex-grow: 1;
              text-align: $rdirection;
            }
          }
        }
      }
    }
  }
}

.viewcart {
  .payments-icon {
    .logo {
      &_visa {
        background: url('/media/images/checkout/payment_logo/BB-VISA.png') no-repeat;
        background-size: 100%;
      }
      &_maestro {
        background: url('/media/images/checkout/payment_logo/BB_maestro.gif') no-repeat;
        background-size: 100%;
        width: 25px;
        height: 30px;
      }
      &_mc {
        background: url('/media/images/checkout/payment_logo/mc.gif') no-repeat;
        background-size: 100%;
        width: 25px;
        height: 30px;
      }
      &_paypal {
        background: url('/media/images/checkout/payment_logo/BB-PP-logo.svg') no-repeat;
        background-size: 100%;
      }
      &_sofort {
        background: url('/media/images/checkout/payment_logo/CC_Sofort.svg') no-repeat;
        background-size: 100%;
      }
    }
  }
  .image_section_cc {
    height: 25px;
  }
}

.opc__payment {
  .payment-option-container {
    .payment-option-block {
      .group1 {
        width: 100%;
      }
    }
  }
}
