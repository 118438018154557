.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
  }
  .store-locator--mobile {
    .resp-tabs-list {
      .resp-tab-item:nth-of-type(2) {
        display: none;
      }
    }
  }
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 30px !important;
  }
  .waitlist_message {
    margin-top: 0px;
  }
  .email_input {
    input {
      margin-bottom: 5px !important;
    }
  }
  &.bis-mobile {
    .email_input {
      width: 100%;
    }
  }
}

.spp-product-layout {
  .sticky-add-to-bag__inner {
    @if $cr22 == false {
      padding: 40px 2px 10px;
    }
  }
  .sticky-add-to-bag__cta--shaded {
    @include breakpoint($tablet-down) {
      @if $cr22 == false {
        width: 100%;
        padding: 0 5px;
      } @else {
        width: 50%;
      }
    }
  }
}

.site-footer {
  .site-footer__wrap {
    .block-template-gnav-btt-v2 {
      a {
        &.sticky-back-to-top-v2 {
          left: 16px;
        }
      }
    }
  }
}

.search-results__header {
  .search-form__fields {
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 181px;
    }
    input.search-form__submit {
      margin-#{$ldirection}: 0;
    }
  }
}

.lp_radio_button {
  input[type='radio'] {
    display: none;
  }
}

.product-brief {
  .product-brief__bottom-mobile {
    .product-brief__add-to-bag {
      width: 100%;
      text-align: center;
    }
    .product-brief__short-desc-link {
      display: none;
    }
  }
}
