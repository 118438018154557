$bb-sans: 'BBSans Regular', Helvetica, Arial, sans-serif !default;
$bbtext-regular: 'BBText Regular', Helvetica, Arial, sans-serif !default;
$bbtext-regular-italic: 'BBText Regular Italic', Helvetica, Arial, sans-serif !default;
$bbtext-bold: 'BBText Bold', Helvetica, Arial, sans-serif !default;
$bbtext-bold-italic: 'BBText Bold Italic', Helvetica, Arial, sans-serif !default;
$bbtext-light: 'BBText Light', Helvetica, Arial, sans-serif !default;
$bbtext-light-italic: 'BBText Light Italic', Helvetica, Arial, sans-serif !default;

body {
  font-family: $bbtext-regular;
}

span.site-utils__link-text,
span.site-utils__link,
h2.signin-panel__title,
.bold,
.brandon-text-bold,
.button,
.button--inverted,
.button--underline,
.link,
.continue-shopping,
.cart-item__product-name,
a.remove_link,
.button--secondary,
div.foundation-finder-result-page__menu_item.active,
div.color-bar-widget__label {
  font-family: $bbtext-bold;
}

.t1,
.t2,
.t3,
.t4,
.t5 {
  font-family: $bb-sans;
}

.content {
  .anchored-menu-reference {
    a {
      &:hover,
      &.active {
        font-family: $bbtext-regular;
      }
    }
    .hed {
      font-family: $bbtext-bold;
    }
  }
}

.content-block {
  .content-block__content,
  .content-block__content-buttons {
    .button--bare,
    .button--default {
      @if $cr22 == false {
        font-family: $bbtext-bold;
      } @else {
        font-family: $bbtext-regular;
      }
    }
  }
  .content-block__line--header {
    .style--bare {
      font-family: $bb-sans;
    }
  }
}

.tabbed-products-block {
  .tabbed-products-block__tab {
    font-family: $bbtext-regular;
    &.current {
      font-family: $bbtext-bold;
    }
  }
}

.content-formatter {
  .content-formatter__content {
    .content-formatter__rendered {
      & > .content-block--basic-tout {
        .content-block__content {
          .content-block__content-text {
            .content-block__line--content--1 {
              p,
              .custom-text {
                font-family: $bbtext-bold;
              }
            }
            .content-block__line--content--2 {
              p,
              .custom-text {
                font-family: $bbtext-regular;
              }
            }
            .content-block__content-buttons {
              font-family: $bbtext-regular;
            }
          }
        }
      }
      .content-block {
        .button {
          &--bare,
          &--default {
            font-family: $bbtext-bold;
          }
        }
        .product-comparison-block__row {
          font-family: $bbtext-bold;
        }
      }
      .mantle-custom-text {
        p {
          @if $cr22 == false {
            font-family: $bbtext-regular !important;
          } @else {
            font-family: $bb-sans !important;
          }
        }
      }
    }
  }
}

.site-footer {
  font-family: $bbtext-regular;
  .site-footer-bottom {
    font-family: $bbtext-regular;
  }
  .site-footer__column-title,
  .site-footer-contact__link,
  .site-footer-social-links__header {
    font-family: $bbtext-bold;
  }
  .site-footer__column {
    .menu__item,
    &:nth-child(2) .menu__item {
      font-family: $bbtext-regular;
    }
  }
  .country-select__selectbox {
    font-family: $bbtext-regular;
  }
}

.product-brief {
  .product-brief__header,
  .product-brief-shades__name,
  .product-brief__sub-line {
    font-family: $bbtext-bold;
  }
  .product-brief__bottom-mobile {
    .product-brief__add-to-bag {
      .product__button--add-to-bag,
      .button--disabled {
        @if $cr22 == false {
          font-family: $bbtext-bold;
        } @else {
          font-family: $bbtext-regular;
        }
      }
      .product__notify-me > li {
        .button {
          @if $cr22 == false {
            font-family: $bbtext-bold;
          } @else {
            font-family: $bbtext-regular;
          }
        }
      }
    }
  }
}

.viewcart-buttons-panel {
  a.continue-checkout {
    font-family: $bbtext-bold;
  }
}

.checkout {
  &__panel-title,
  &__subtitle {
    font-family: $bbtext-bold;
  }
  .offer-code-panel {
    input[type='submit'].btn {
      font-family: $bbtext-bold;
    }
  }
  a.btn {
    font-family: $bbtext-bold;
  }
}

.samples-panel {
  .product {
    .sample-select-button {
      font-family: $bbtext-bold;
    }
    .product-name {
      font-family: $bbtext-bold;
    }
  }
  .return-link {
    a {
      font-family: $bbtext-bold;
    }
  }
}

.selectBox-options,
.selectbox,
.selectBox {
  a,
  span {
    font-family: $bbtext-regular;
  }
}

div.spp-product-layout {
  .product-full {
    &__short-desc {
      font-family: $bbtext-regular;
    }
    &__title,
    &-shades__detail-shadename,
    &__data-heading-label {
      @if $cr22 == false {
        font-family: $bbtext-bold;
      } @else {
        font-family: $bb-sans;
      }
    }
    &__bag-btn {
      .product__notify-me {
        .button {
          font-family: $bbtext-bold;
        }
      }
      .button {
        font-family: $bbtext-bold;
      }
    }
  }
  .sticky-add-to-bag {
    &__name {
      font-family: $bbtext-regular;
    }
    &__cta {
      .product__notify-me {
        .button {
          @if $cr22 == false {
            font-family: $bbtext-bold;
          } @else {
            font-family: $bbtext-regular;
          }
        }
      }
      .button {
        @if $cr22 == false {
          font-family: $bbtext-bold;
        } @else {
          font-family: $bbtext-regular;
        }
      }
    }
  }
  .shade-select {
    font-family: $bbtext-bold;
  }
  &__content-item-heading {
    font-family: $bb-sans;
  }
}

section.pr-review-snapshot-block,
div.p-w-r {
  font-family: $bbtext-regular;
}

div.popup-offer-overlay-cbox {
  .popup-offer {
    &__heading {
      font-family: $bb-sans;
    }
    &__body {
      font-family: $bbtext-regular;
    }
    &__submit-wrap {
      input[type='text']::placeholder {
        font-family: $bbtext-bold;
      }
    }
  }
}

#pr-review-snapshot {
  .pr-review-snapshot-block {
    dd {
      font-family: $bbtext-regular;
    }
    dt {
      font-family: $bbtext-bold;
    }
  }
}

.section-esearch {
  #search-wrapper {
    #results-container {
      div#col-1 {
        font-family: $bbtext-bold;
      }
      div#col-2 {
        #product-results {
          .results-header {
            #summary {
              font-family: $bbtext-bold;
            }
          }
        }
      }
      .pagination-container {
        .pagination {
          a {
            font-family: $bbtext-regular;
          }
        }
      }
    }
    .results-header {
      .section-header {
        &__inner {
          font-family: $bbtext-regular;
        }
      }
    }
  }
}

select.mpp-sort-menu {
  &.selectBox {
    font-family: $bbtext-bold;
  }
  option {
    font-family: $bbtext-regular;
  }
}

.mpp-container {
  .mpp-filter {
    &-set__button,
    &-set__footer--cta-reset {
      font-family: $bbtext-regular;
    }
    &-set__header {
      .mpp-filter-title {
        @if $cr22 == false {
          font-family: $bbtext-bold;
        } @else {
          font-family: $bbtext-regular;
        }
      }
    }
    &-set__title,
    &-cta {
      @if $cr22 == false {
        font-family: $bbtext-bold;
      } @else {
        font-family: $bbtext-regular;
      }
    }
  }
  .mpp-sort-menu {
    span {
      @if $cr22 == false {
        font-family: $bbtext-bold;
      } @else {
        font-family: $bbtext-regular;
      }
    }
  }
}

div.product-feature__left {
  h1 {
    font-family: $bb-sans;
  }
}

.crushed_lip {
  .block_shade-dot {
    .block_container__title {
      span {
        font-family: $bb-sans;
      }
    }
  }
}

.videos-plus-module {
  .videos-plus-module_hero__title {
    span {
      font-family: $bb-sans;
    }
  }
}

.block_power3 {
  .block_container__title {
    span {
      font-family: $bb-sans;
    }
  }
}

div.shade-finder-block__filter {
  font-family: $bbtext-regular;
  &.active {
    font-family: $bbtext-bold;
  }
}

.homepage_evergreen_services_body {
  div.hed {
    font-family: $bbtext-bold;
  }
}

div.split-width-box__description,
div.small-left-image-box__description,
div.foundation-finder-result-page__menu_item,
div.foundation-finder-result__complete-your-regimen {
  font-family: $bbtext-regular;
}

.ff-shades-content {
  div.range-slider-item__label,
  div.range-slider-item__description {
    font-family: $bbtext-regular;
  }
}

.small-left-image-box,
.split-width-box,
.image-row-box {
  .label {
    span {
      font-family: $bbtext-bold;
    }
  }
}

div.ff-product-card {
  .product-brief__top-shadename,
  .ff-product-card__purpose {
    font-family: $bbtext-regular;
  }
  .product-brief__header {
    font-family: $bbtext-bold;
  }
}

div.quiz-landing-page-formatter {
  &__header__title {
    font-family: $bbtext-bold;
  }
  &__header__description,
  &__loading__text {
    font-family: $bbtext-regular;
  }
  &__results-footer {
    a.retake-quiz {
      font-family: $bbtext-regular;
    }
  }
  &__footer-cta {
    a.button {
      font-family: $bbtext-bold;
    }
  }
}

.image-row-content {
  div.range-slider-item__label {
    font-family: $bbtext-regular;
  }
}

.range-slider-carousel__view-all {
  a.range-slider-view-all-link {
    font-family: $bbtext-regular;
  }
}

div.form-options-formatter {
  &__header {
    .style--bare {
      font-family: $bb-sans;
    }
  }
  &__subheader {
    .style--bare {
      font-family: $bbtext-regular;
    }
  }
}

div.image-row-box {
  .form-option-select-link {
    font-family: $bbtext-regular;
  }
}
