.confirm {
  .create_account_benefits {
    @include breakpoint($medium-up) {
      margin-top: -15px;
      margin-bottom: 20px;
    }
    ul li {
      &:before {
        content: '-';
        text-indent: -5px;
        margin-right: 5px;
      }
    }
  }
}
