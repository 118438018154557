#address_form_container {
  .address-form__content {
    .form_element {
      input {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
